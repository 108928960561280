<template>
  <div class="annual-suppliers">
    <div class="page-header">
      <h2>年度供应商分析</h2>
      <p class="sub-title">全方位分析供应商业绩表现及发展趋势</p>
    </div>
    <div class="page-content" v-loading="loading" element-loading-text="数据加载中...">
      <!-- 搜索和导出区域 -->
      <div class="search-export-row">
        <div class="right-actions">
          <el-date-picker v-model="selectedYear" type="year" placeholder="选择年份" :picker-options="yearPickerOptions" @change="handleYearChange" value-format="yyyy" class="year-picker">
          </el-date-picker>

          <el-button type="danger" @click="exportData" class="export-btn">
            <i class="el-icon-download"></i> 导出数据
          </el-button>
        </div>
      </div>
      <!-- 数据统计卡片 -->
      <el-row :gutter="20" class="stat-cards">
        <el-col :xs="24" :sm="12" :md="12" :lg="6" v-for="(item, index) in statCards" :key="index" class="stat-card-col">
          <el-card shadow="hover">
            <div class="stat-item">
              <div class="stat-title">{{ item.title }}</div>
              <div class="stat-value">{{ item.prefix }}{{ item.formatter(item.value) }}</div>
              <div class="stat-trend" :class="{ 'up': item.growth > 0, 'down': item.growth < 0 }">
                {{ item.growth > 0 ? '↑' : '↓' }} {{ formatGrowthRate(item.growth) }}{{ item.unit }} {{ item.suffix }}
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>

      <!-- 供应商业绩趋势和画像分析 -->
      <el-row :gutter="20" class="chart-section">
        <el-col :xs="24" :sm="24" :md="24" :lg="16" class="chart-col">
          <el-card shadow="hover" class="chart-card">
            <div slot="header" class="card-header">
              <span>供应商业绩趋势</span>
              <div class="chart-tabs">
                <el-radio-group v-model="trendChartType" size="small">
                  <el-radio-button label="amount">合作金额</el-radio-button>
                  <el-radio-button label="kol_count">达人数量</el-radio-button>
                  <el-radio-button label="customer_count">服务客户数</el-radio-button>
                </el-radio-group>
              </div>
            </div>
            <div class="chart-container">
              <div ref="trendChart" class="chart"></div>
            </div>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="8" class="chart-col">
          <el-card shadow="hover" class="chart-card">
            <div slot="header" class="card-header">
              <span>供应商画像分析</span>
            </div>
            <div class="chart-container">
              <div ref="radarChart" class="chart"></div>
            </div>
          </el-card>
        </el-col>
      </el-row>

      <!-- 供应商排名 -->
      <el-card shadow="hover" class="ranking-card">
        <div slot="header" class="card-header">
          <span>供应商排名</span>
        </div>
        <el-table :data="filteredSupplierRanking" border style="width: 100%">
          <el-table-column type="index" label="排名" width="80"></el-table-column>
          <el-table-column prop="supplier_name" label="供应商信息" min-width="250">
            <template slot-scope="scope">
              <div class="supplier-info">
                <div class="supplier-name">{{ scope.row.supplier_name }}</div>
                <div class="supplier-product">{{ scope.row.supplier_product }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="total_amount" label="合作金额" width="150">
            <template slot-scope="scope">
              ¥ {{ formatAmount(scope.row.total_amount) }}
            </template>
          </el-table-column>
          <el-table-column prop="order_count" label="下单次数" width="120">
            <template slot-scope="scope">
              {{ formatNumber(scope.row.order_count) }}
            </template>
          </el-table-column>
          <el-table-column prop="customer_count" label="服务客户数" width="120">
            <template slot-scope="scope">
              {{ formatNumber(scope.row.customer_count) }}
            </template>
          </el-table-column>
          <el-table-column prop="satisfaction" label="满意度" width="180">
            <template slot-scope="scope">
              <el-rate v-model="scope.row.formattedSatisfaction" disabled text-color="#ff9900">
              </el-rate>
            </template>
          </el-table-column>
          <el-table-column prop="change_rate" label="环比变化" width="150">
            <template slot-scope="scope">
              <div :class="['change-rate', scope.row.change_rate > 0 ? 'up' : 'down']">
                {{ scope.row.change_rate > 0 ? '↑' : '↓' }} {{ formatGrowthRate(scope.row.change_rate) }}%
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import request from '@/utils/request';

export default {
  name: 'AnnualSuppliers',
  data () {
    const currentYear = new Date().getFullYear();
    const defaultYear = currentYear.toString();

    return {
      loading: false,
      selectedYear: defaultYear,
      yearPickerOptions: {
        disabledDate (time) {
          return time.getFullYear() < 2020;
        }
      },
      overviewData: {
        total_amount: 0,
        total_amount_growth: 0,
        supplier_count: 0,
        supplier_count_growth: 0,
        kol_count: 0,
        kol_count_growth: 0,
        order_count: 0,
        order_count_growth: 0,
        avg_satisfaction: 0,
        satisfaction_growth: 0,
        settlement_net_amount: 0,
        settlement_net_amount_growth: 0,
        payable_amount: 0,
        payable_amount_growth: 0,
        paid_amount: 0,
        paid_amount_growth: 0,
        unpaid_amount: 0,
        unpaid_amount_growth: 0
      },
      supplierRanking: [],
      monthlyTrend: [],
      trendChartType: 'amount',
      trendChart: null,
      radarChart: null,
      resizeHandler: null
    }
  },
  computed: {
    filteredSupplierRanking () {
      return this.supplierRanking;
    },
    // 统计卡片数据
    statCards () {
      return [
        {
          title: '年度总合作金额',
          value: this.overviewData.total_amount,
          growth: this.overviewData.total_amount_growth,
          formatter: this.formatAmount,
          prefix: '¥ ',
          unit: '%',
          suffix: '同比增长'
        },
        {
          title: '总合作达人数',
          value: this.overviewData.kol_count,
          growth: this.overviewData.kol_count_growth,
          formatter: this.formatNumber,
          prefix: '',
          unit: '%',
          suffix: '同比增长'
        },
        {
          title: '总合作次数',
          value: this.overviewData.order_count,
          growth: this.overviewData.order_count_growth,
          formatter: this.formatNumber,
          prefix: '',
          unit: '%',
          suffix: '同比增长'
        },
        {
          title: '平均满意度',
          value: this.overviewData.avg_satisfaction,
          growth: this.overviewData.satisfaction_growth,
          formatter: this.formatSatisfaction,
          prefix: '',
          unit: '',
          suffix: '同比提升'
        },
        {
          title: '结算净额',
          value: this.overviewData.settlement_net_amount,
          growth: this.overviewData.settlement_net_amount_growth,
          formatter: this.formatAmount,
          prefix: '¥ ',
          unit: '%',
          suffix: '同比增长'
        },
        {
          title: '应付金额',
          value: this.overviewData.payable_amount,
          growth: this.overviewData.payable_amount_growth,
          formatter: this.formatAmount,
          prefix: '¥ ',
          unit: '%',
          suffix: '同比增长'
        },
        {
          title: '已付款金额',
          value: this.overviewData.paid_amount,
          growth: this.overviewData.paid_amount_growth,
          formatter: this.formatAmount,
          prefix: '¥ ',
          unit: '%',
          suffix: '同比增长'
        },
        {
          title: '未付款金额',
          value: this.overviewData.unpaid_amount,
          growth: this.overviewData.unpaid_amount_growth,
          formatter: this.formatAmount,
          prefix: '¥ ',
          unit: '%',
          suffix: '同比增长'
        }
      ];
    }
  },
  watch: {
    trendChartType () {
      this.renderTrendChart();
    }
  },
  mounted () {
    // 确保默认年份设置正确
    if (!this.selectedYear) {
      this.selectedYear = new Date().getFullYear().toString();
    } else if (this.selectedYear === 1970 || this.selectedYear === '1970') {
      this.selectedYear = new Date().getFullYear().toString();
    }

    this.fetchData();
  },
  methods: {
    async fetchData () {
      this.loading = true;
      try {
        // 确保年份是正确的数字格式
        let year = this.selectedYear;
        if (typeof year === 'string') {
          year = parseInt(year, 10);
        } else if (year instanceof Date) {
          year = year.getFullYear();
        }
        const response = await request.get(`/api/supplier_analysis`, {
          params: {
            year: year
          }
        });
        const { overview, supplier_ranking, monthly_trend } = response.data;
        if (overview) {
          this.overviewData = {
            ...this.overviewData,
            ...overview
          };
        }
        if (supplier_ranking && Array.isArray(supplier_ranking)) {
          this.supplierRanking = supplier_ranking.map(supplier => {
            return {
              ...supplier,
              formattedSatisfaction: this.formatSatisfaction(supplier.satisfaction)
            };
          }).slice(0, 10); // 只取前10条数据，提高渲染性能
        } else {
          this.supplierRanking = [];
        }

        // 处理月度趋势数据
        if (monthly_trend && Array.isArray(monthly_trend)) {
          this.monthlyTrend = monthly_trend;
        } else {
          this.monthlyTrend = [];
        }

        setTimeout(() => {
          this.initCharts();
          this.loading = false;
        }, 0);
      } catch (error) {
        console.error('获取年度分析数据失败:', error);
        if (error.message && error.message.includes('timeout')) {
          this.$message.error('请求超时，请检查网络或稍后重试');
        } else {
          this.$message.error('获取年度分析数据失败');
        }
        this.loading = false;
      }
    },

    handleYearChange () {
      // 确保selectedYear是字符串类型，与value-format="yyyy"匹配
      if (typeof this.selectedYear === 'number') {
        this.selectedYear = this.selectedYear.toString();
      } else if (this.selectedYear instanceof Date) {
        this.selectedYear = this.selectedYear.getFullYear().toString();
      }
      this.fetchData();
    },

    exportData () {
      this.$message.success('数据导出功能待实现');
    },

    formatAmount (amount) {
      if (amount === null || amount === undefined) return '0';
      // 处理字符串格式的金额
      if (typeof amount === 'string') {
        amount = parseFloat(amount);
      }
      // 处理科学计数法或异常大数值
      if (isNaN(amount) || !isFinite(amount) || Math.abs(amount) > 1e15) {
        return '0';
      }
      return amount.toLocaleString('zh-CN', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
    },

    formatNumber (num) {
      if (num === null || num === undefined) return '0';
      // 处理字符串格式的数字
      if (typeof num === 'string') {
        num = parseFloat(num);
      }
      // 处理科学计数法或异常大数值
      if (isNaN(num) || !isFinite(num) || Math.abs(num) > 1e15) {
        return '0';
      }
      return num.toLocaleString('zh-CN');
    },

    formatGrowthRate (rate) {
      // 处理异常增长率
      if (rate === null || rate === undefined) return '0';
      if (typeof rate === 'string') {
        rate = parseFloat(rate);
      }
      if (isNaN(rate) || !isFinite(rate) || Math.abs(rate) > 1000) {
        // 对于异常大的增长率，返回更合理的值
        return rate < 0 ? '-100' : '100';
      }
      return Math.abs(rate).toFixed(1);
    },

    formatSatisfaction (satisfaction) {
      // 处理异常满意度评分
      if (satisfaction === null || satisfaction === undefined) return 0;
      if (typeof satisfaction === 'string') {
        satisfaction = parseFloat(satisfaction);
      }
      if (isNaN(satisfaction) || !isFinite(satisfaction) || Math.abs(satisfaction) > 5) {
        // 如果满意度超出正常范围，返回默认值
        return 4.5; // 默认4.5分
      }
      return parseFloat(satisfaction);
    },

    initCharts () {
      try {
        this.initTrendChart();
        this.initRadarChart();
      } catch (error) {
        console.error('初始化图表失败:', error);
      }
    },

    initTrendChart () {
      try {
        if (!this.$refs.trendChart) {
          console.warn('趋势图DOM元素不存在');
          return;
        }

        if (this.trendChart) {
          this.trendChart.dispose();
        }

        this.trendChart = echarts.init(this.$refs.trendChart);
        this.renderTrendChart();

        if (!this.resizeHandler) {
          this.resizeHandler = this.debounce(() => {
            if (this.trendChart) {
              this.trendChart.resize();
            }
            if (this.radarChart) {
              this.radarChart.resize();
            }
          }, 200);
          window.addEventListener('resize', this.resizeHandler);
        }
      } catch (error) {
        console.error('初始化趋势图失败:', error);
      }
    },

    renderTrendChart () {
      try {
        if (!this.trendChart) return;

        const months = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];

        // 根据选中的图表类型处理对应数据
        let chartTitle = '';
        let chartData = Array(12).fill(0);
        let yAxisFormatter = null;
        let lineColor = '#409EFF';
        let areaColorStart = 'rgba(64, 158, 255, 0.2)';
        let areaColorEnd = 'rgba(64, 158, 255, 0)';

        if (this.monthlyTrend && this.monthlyTrend.length > 0) {
          switch (this.trendChartType) {
            case 'amount':
              chartTitle = '合作金额';
              lineColor = '#409EFF';
              areaColorStart = 'rgba(64, 158, 255, 0.3)';
              areaColorEnd = 'rgba(64, 158, 255, 0.05)';
              yAxisFormatter = function (value) {
                if (value >= 1000000) {
                  return (value / 1000000).toFixed(1) + 'M';
                } else if (value >= 1000) {
                  return (value / 1000).toFixed(0) + 'k';
                }
                return value;
              };
              this.monthlyTrend.forEach(item => {
                const month = parseInt(item.month, 10) - 1; // 转为0基索引
                if (month >= 0 && month < 12) {
                  const value = parseFloat(item.amount);
                  if (!isNaN(value) && isFinite(value)) {
                    chartData[month] = value;
                  }
                }
              });
              break;
            case 'kol_count':
              chartTitle = '达人数量';
              lineColor = '#67C23A';
              areaColorStart = 'rgba(103, 194, 58, 0.3)';
              areaColorEnd = 'rgba(103, 194, 58, 0.05)';
              yAxisFormatter = function (value) {
                return value;
              };
              this.monthlyTrend.forEach(item => {
                const month = parseInt(item.month, 10) - 1;
                if (month >= 0 && month < 12) {
                  const value = parseFloat(item.kol_count);
                  if (!isNaN(value) && isFinite(value)) {
                    chartData[month] = value;
                  }
                }
              });
              break;
            case 'customer_count':
              chartTitle = '服务客户数';
              lineColor = '#F56C6C';
              areaColorStart = 'rgba(245, 108, 108, 0.3)';
              areaColorEnd = 'rgba(245, 108, 108, 0.05)';
              yAxisFormatter = function (value) {
                return value;
              };
              this.monthlyTrend.forEach(item => {
                const month = parseInt(item.month, 10) - 1;
                if (month >= 0 && month < 12) {
                  const value = parseFloat(item.customer_count);
                  if (!isNaN(value) && isFinite(value)) {
                    chartData[month] = value;
                  }
                }
              });
              break;
            default:
              chartTitle = '合作金额';
              this.monthlyTrend.forEach(item => {
                const month = parseInt(item.month, 10) - 1;
                if (month >= 0 && month < 12) {
                  const value = parseFloat(item.amount);
                  if (!isNaN(value) && isFinite(value)) {
                    chartData[month] = value;
                  }
                }
              });
          }
        }

        const option = {
          title: {
            text: chartTitle,
            left: 'center',
            top: 10,
            textStyle: {
              fontSize: 14,
              color: '#666',
              fontWeight: 'normal'
            },
            show: false
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: months,
              axisLine: {
                lineStyle: {
                  color: '#E0E0E0'
                }
              },
              axisTick: {
                show: false
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              axisLabel: {
                formatter: yAxisFormatter || function (value) {
                  if (value >= 1000000) {
                    return (value / 1000000).toFixed(1) + 'M';
                  } else if (value >= 1000) {
                    return (value / 1000).toFixed(0) + 'k';
                  }
                  return value;
                },
                color: '#999'
              },
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              splitLine: {
                lineStyle: {
                  color: '#F0F0F0'
                }
              }
            }
          ],
          series: [
            {
              name: chartTitle,
              type: 'line',
              smooth: true,
              symbol: 'circle',
              symbolSize: 5,
              showSymbol: false,
              lineStyle: {
                width: 3,
                color: lineColor
              },
              areaStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: areaColorStart
                  }, {
                    offset: 1, color: areaColorEnd
                  }]
                }
              },
              emphasis: {
                focus: 'series',
                itemStyle: {
                  color: lineColor,
                  borderColor: '#fff',
                  borderWidth: 2,
                  shadowColor: 'rgba(0, 0, 0, 0.2)',
                  shadowBlur: 5
                }
              },
              data: chartData
            }
          ]
        };

        this.trendChart.setOption(option, true);
      } catch (error) {
        console.error('渲染趋势图失败:', error);
      }
    },

    initRadarChart () {
      try {
        if (!this.$refs.radarChart) {
          console.warn('雷达图DOM元素不存在');
          return;
        }

        if (this.radarChart) {
          this.radarChart.dispose();
        }

        this.radarChart = echarts.init(this.$refs.radarChart);

        // 计算平均满意度（使用正常范围的值）
        let avgSatisfaction = 4.5;
        if (this.supplierRanking.length > 0) {
          const validRatings = this.supplierRanking
            .map(s => this.formatSatisfaction(s.satisfaction))
            .filter(s => s > 0 && s <= 5);

          if (validRatings.length > 0) {
            avgSatisfaction = validRatings.reduce((sum, val) => sum + val, 0) / validRatings.length;
          }
        }

        // 基于平均满意度生成雷达图数据
        const normalizedValue = Math.min(Math.max(avgSatisfaction, 1), 5); // 确保在1-5之间
        const scaledValue = (normalizedValue / 5) * 100; // 转换为百分比值

        const option = {
          backgroundColor: 'transparent',
          radar: {
            indicator: [
              { name: '合作频率', max: 100 },
              { name: '质量', max: 100 },
              { name: '准时度', max: 100 },
              { name: '成本', max: 100 },
              { name: '服务质量', max: 100 }
            ],
            shape: 'circle',
            splitNumber: 5,
            axisName: {
              color: '#666',
              fontSize: 12
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(211, 216, 219, 0.6)'
              }
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: ['rgba(255, 255, 255, 0.1)', 'rgba(245, 245, 245, 0.2)']
              }
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(211, 216, 219, 0.6)'
              }
            }
          },
          series: [
            {
              name: '供应商画像',
              type: 'radar',
              lineStyle: {
                width: 2,
                color: '#5B8FF9'
              },
              symbol: 'circle',
              symbolSize: 6,
              emphasis: {
                lineStyle: {
                  width: 4,
                  shadowColor: 'rgba(91, 143, 249, 0.5)',
                  shadowBlur: 10
                },
                itemStyle: {
                  color: '#5B8FF9',
                  borderColor: '#fff',
                  borderWidth: 2,
                  shadowColor: 'rgba(0, 0, 0, 0.2)',
                  shadowBlur: 5
                }
              },
              data: [
                {
                  value: [
                    scaledValue * 0.9, // 合作频率
                    scaledValue * 1.1, // 质量
                    scaledValue * 0.85, // 准时度
                    scaledValue * 0.95, // 成本
                    scaledValue * 1.05  // 服务质量
                  ],
                  name: '平均表现',
                  areaStyle: {
                    color: {
                      type: 'radial',
                      x: 0.5,
                      y: 0.5,
                      r: 0.8,
                      colorStops: [
                        {
                          offset: 0,
                          color: 'rgba(91, 143, 249, 0.5)'
                        },
                        {
                          offset: 1,
                          color: 'rgba(91, 143, 249, 0.1)'
                        }
                      ]
                    }
                  },
                  itemStyle: {
                    color: '#5B8FF9',
                    borderColor: '#fff',
                    borderWidth: 1,
                    shadowColor: 'rgba(0, 0, 0, 0.2)',
                    shadowBlur: 3
                  }
                }
              ]
            }
          ]
        };

        this.radarChart.setOption(option, true);
      } catch (error) {
        console.error('初始化雷达图失败:', error);
      }
    },

    debounce (fn, delay) {
      let timer = null;
      return function () {
        const context = this;
        const args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
          fn.apply(context, args);
        }, delay);
      };
    },

    beforeDestroy () {
      if (this.resizeHandler) {
        window.removeEventListener('resize', this.resizeHandler);
      }

      if (this.trendChart) {
        this.trendChart.dispose();
        this.trendChart = null;
      }

      if (this.radarChart) {
        this.radarChart.dispose();
        this.radarChart = null;
      }
    }
  }
}
</script>

<style scoped>
.annual-suppliers {
  padding: 20px;
}

.page-header {
  margin-bottom: 20px;
}

.page-header h2 {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 22px;
}

.sub-title {
  color: #666;
  font-size: 14px;
  margin-top: 0;
}

.search-export-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.right-actions {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

.year-picker {
  width: 170px;
  margin-bottom: 10px;
}

.export-btn {
  background-color: #f56c6c;
  border-color: #f56c6c;
  color: white;
  margin-bottom: 10px;
}

.stat-cards {
  margin-bottom: 20px;
}

.stat-card-col {
  margin-bottom: 20px;
}

.stat-item {
  text-align: center;
  padding: 10px 0;
}

.stat-title {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.stat-value {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  word-break: break-all;
}

.stat-trend {
  font-size: 12px;
}

.stat-trend.up {
  color: #67c23a;
}

.stat-trend.down {
  color: #f56c6c;
}

.chart-section {
  margin-bottom: 20px;
}

.chart-col {
  margin-bottom: 20px;
}

.chart-card {
  height: 100%;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.chart-tabs {
  margin-top: 10px;
}

.chart-container {
  height: 300px;
  min-height: 250px;
}

.chart {
  width: 100%;
  height: 100%;
}

.ranking-card {
  margin-bottom: 20px;
  overflow-x: auto;
}

.supplier-info {
  display: flex;
  flex-direction: column;
}

.supplier-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.supplier-product {
  font-size: 12px;
  color: #888;
}

.change-rate {
  font-weight: bold;
}

.change-rate.up {
  color: #67c23a;
}

.change-rate.down {
  color: #f56c6c;
}

/* 响应式样式 */
@media screen and (max-width: 768px) {
  .annual-suppliers {
    padding: 10px;
  }

  .page-header h2 {
    font-size: 20px;
  }

  .search-export-row {
    justify-content: center;
  }

  .right-actions {
    width: 100%;
    justify-content: center;
  }

  .stat-value {
    font-size: 20px;
  }

  .card-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .chart-tabs {
    margin-top: 10px;
    width: 100%;
    overflow-x: auto;
  }

  .chart-container {
    height: 250px;
  }
}

@media screen and (max-width: 480px) {
  .stat-value {
    font-size: 18px;
  }

  .chart-container {
    height: 200px;
  }
}
</style>